exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-comprobar-js": () => import("./../../../src/pages/Comprobar.js" /* webpackChunkName: "component---src-pages-comprobar-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/Elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("./../../../src/pages/Generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-requisitos-js": () => import("./../../../src/pages/Requisitos.js" /* webpackChunkName: "component---src-pages-requisitos-js" */),
  "component---src-pages-terminos-js": () => import("./../../../src/pages/Terminos.js" /* webpackChunkName: "component---src-pages-terminos-js" */),
  "component---src-pages-tramites-js": () => import("./../../../src/pages/Tramites.js" /* webpackChunkName: "component---src-pages-tramites-js" */)
}

